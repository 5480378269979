module.exports = new Promise((resolve, reject) => {
	const remoteUrlWithVersion = '$$MFE_BIC_DOMAINremoteEntry.js?v=' + (new Date()).getTime().toString();
	const script = document.createElement('script')
	script.src = remoteUrlWithVersion
	script.onerror = reject
	script.onload = () => {
		// the injected script has loaded and is available on window
		// we can now resolve this Promise
		const proxy = {
			get: (request) => window.bic.get(request),
			init: (arg) => {
				try {
					return window.bic.init(arg)
				} catch (e) {
					console.log('remote container already initialized')
				}
			},
		}
		resolve(proxy)
	}
	// inject this script with the src set to the versioned remoteEntry.js
	document.head.appendChild(script)
});